import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Withdraws {
  getWithdrawLogs = (payload={}) => {
    return axios
      .get(`${API_URL}api/v1/merchants/withdraws?search=${payload?.search || ""}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // getCommissionLogs = () => {
  //   return axios
  //     .get(`${API_URL}api/v1/merchants/transactions/commission-logs?page=1&limit=2`, {
  //       headers: {
  //         Authorization: `Token ${ACCESS_TOKEN}`,
  //       },
  //     })
  //     .then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       } else {
  //         return {
  //           data: {
  //             results: [],
  //           },
  //         };
  //       }
  //     })
  //     .catch(function (error) {
  //       return error?.response?.data;
  //     });
  // };
  getWithdrawMethods = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/user-withdraw-methods`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getMethods = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/withdraw-methods`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  addWithdrawMethod = (formData) => {
    const url = `${API_URL}api/v1/merchants/user-withdraw-methods`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  withdrawMoney = (formData) => {
    const url = `${API_URL}api/v1/merchants/withdraws`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
