import Auth from "./Auth";
import Withdraws from "./Withdraw";
import Notifications from "./Notifications";
import QRCode from "./QRcode";
import Messages from "./Messages";
import News from "./News";
import GlobalSettings from "./GlobalSettings";
import FixedPage from "./FixedPage";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import ApiKeys from "./Apikeys";
import Support from "./Support";
import Transactions from "./Transactions";
import Kyc from "./Kyc";

const API = {
  Auth,
  Withdraws,
  ApiKeys,
  Notifications,
  QRCode,
  Messages,
  News,
  GlobalSettings,
  FixedPage,
  Dashboard,
  Profile,
  Support,
  Transactions,
  Kyc
};

export default API;
