/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Form, Input, Button, message, Row, Col, Card, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Spinner from "../../common/spinner";
import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import { setUserData } from "../../../utils/redux/actions";
import authImg from "../../../images/loginImg.svg";
import handEmojiImg from "../../../images/Emoji.svg";
// import logo from "../../../images/tajira-logo.png";

import "./styles.scss";

const { Title } = Typography;

const Login = () => {
  const [form] = Form.useForm();

  const auth = new API.Auth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [is2fa, setIs2fa] = useState(false);
  const [otp, setOtp] = useState();
  const [userId, setUserId] = useState();

  const handleRefresh = () => {
    window.location.reload(false);
  };

  const onLogin = (payload) => {
    getDataManager(auth?.loginUser, setLoading, payload).then((x) => {
      if (x?.status === true) {
        if (x?.data?.is_2fa_enabled === false) {
          message.success({
            content: "You have successfully logged in",
            duration: 3,
          });
          localStorage.setItem("mecrhant-panel-data", JSON.stringify(x?.data));
          console.log(x?.data);
          dispatch(setUserData(x?.data));
          navigate("/");
          handleRefresh();
        } else {
          setIs2fa(true);
          setUserId(x?.data?.user_id);
        }
      } else {
        const error = getErrorMessage(x?.errors);
        localStorage.removeItem("merchant-panel-data");
        message.error({
          content: x?.message || error || "Error ocurred while logging in",
          duration: 3,
        });
      }
    });
  };
  const handleVerification = () => {
    if (otp?.length === 6) {
      const payload = {
        token: otp,
        user_id: userId,
      };
      console.log(payload)
      getDataManager(auth?.validate2FaSecurity, setLoading, payload).then(
        (x) => {
          if (x?.status === true) {
            message.success({
              content: "You have successfully logged in",
              duration: 3,
            });
            localStorage.setItem("mecrhant-panel-data", JSON.stringify(x?.data));
            console.log(x?.data);
            dispatch(setUserData(x?.data));
            navigate("/");
            handleRefresh();
          } else {
            const error = getErrorMessage(x?.errors);
            message.error({
              content: x?.message || error || "Error ocurred while sending otp",
              duration: 3,
            });
          }
        }
      );
    }
  };

  // const handleForgotPassword = () => {
  //   navigate("/reset-password");
  // };

  return (
    <Row>
      <Col span={12} className="login-right-section">
        <img src={authImg} alt="" />
      </Col>
      <Col span={12} className="login-left-section">
        {!is2fa ? (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <div className="logo-container">
              {/* <img src={logo} alt="" className="logo" /> */}
            </div>
            <Title level={4} className="login-title">
              Login
              <span>
                <img src={handEmojiImg} alt="#" className="emoji-img" />
              </span>
            </Title>
            <div className="login-thankyou-text">Thankyou for getting back</div>
            <Form
              form={form}
              onFinish={onLogin}
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  className="form-input"
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
                className="password-field"
              >
                <Input
                  placeholder="Password"
                  visible={true}
                  type="password"
                  size="large"
                  className="form-input"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <div className="forgot-password-btn-container">
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot Password ?
                </Button>
              </div>
              <Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  type="danger"
                  className="login-submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <div className="dont-have-account">
              Don't have an account?{" "}
              <span
                onClick={() => navigate("/sign-in")}
                className="sin-up-text"
              >
                Sign Up
              </span>
            </div>
          </Card>
        ) : (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <Title level={4} className="login-title">
              2 FA Authentication
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={()=>handleVerification()}
              scrollToFirstError
            >
              <Form.Item label="Otp Code">
                <OtpInput
                  numInputs={6}
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  containerStyle="verification-code-container"
                  inputStyle="one-digit-input"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  // htmlType="submit"
                  size="large"
                  type="primary"
                  className="forget-submit-button"
                  onClick={()=>handleVerification()}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
        {/* <Card className="login-card" bordered={false}>
          {loading && <Spinner />}
          <div className="logo-container">
            <img src={logo} alt="" className="logo" />
          </div>
          <Title level={4} className="login-title">
            Login<span>
            <img src={handEmojiImg} alt="#" className="emoji-img"/>
          </span>
            
          </Title>
          <div className="login-thankyou-text">Thankyou for getting back</div>
          <Form
            form={form}
            onFinish={onLogin}
            scrollToFirstError
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                placeholder="Username"
                size="large"
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
              className="password-field"
            >
              <Input
                placeholder="Password"
                visible={true}
                type="password"
                size="large"
                className="form-input"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="forgot-password-btn-container" >
              <Button type="link" style={{ padding: 0 }} onClick={()=>navigate("/reset-password")} >
                Forgot Password ?
              </Button>
            </div>
            <Form.Item>
              <Button htmlType="submit" size="large" type="danger" className="login-submit-button">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <div className="dont-have-account">
            Don't have an account? <span onClick={()=>navigate("/sign-in")} className="sin-up-text">Sign Up</span>
          </div>
        </Card> */}
      </Col>
    </Row>
  );
};

export default Login;
