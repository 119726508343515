import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Dashboard {
  getMerchantsWallets = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/wallets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getTotalAmountWithdraw = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/transactions/total-withdraw`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getTotalMoneyReceived = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/transactions/total-money-received`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getTotalMoneyOut = () => {
    return axios
      .get(`${API_URL}api/v1/merchants/transactions//total-money-out`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getAllTransactionsChart = () => {
    const url = `${API_URL}api/v1/merchants/transactions/transaction-graph`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
