/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar,FloatButton } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import FloatIcon from "../../images/floatIcon.svg"
import Spinner from "../common/spinner";
import SiderBar from "./sidebar";
import { setUserData } from "../../utils/redux/actions";
import "./styles.scss";

const Dashboard = React.lazy(() => import("../Dashboard"));
const TransactionHistory = React.lazy(() => import("../Transactions/TransactionHistory"));
const WithDrawMoney = React.lazy(() => import("../WithdrawMoney"));
const AddWithDrawMethod = React.lazy(() => import("../WithdrawMoney/AddWithdrawMethod"));
const WithDrawHistory = React.lazy(() => import("../WithdrawHistory"));
const ProfileSettings = React.lazy(() => import("../ProfileSetting"));
const ChangePassword = React.lazy(() => import("../ChangePassword"));
const TwoFactorsAuthentication = React.lazy(() => import("../TwoFactorAuthentication"));
const MyQRCode = React.lazy(() => import("../MyQrCode"));
const BusinessApiKey = React.lazy(() => import("../BusinessApiKey"));
//Support ticket
const SupportTicket = React.lazy(() => import("../SupportTicket"));
const AddnewTicket = React.lazy(() => import("../SupportTicket/AddnewTicket"));
const TicketDetail = React.lazy(() => import("../SupportTicket/TicketDetail"));
const LogoutDevices = React.lazy(() => import("../LogoutDevices"));
const ScreenLock = React.lazy(() => import("../ScreenLock"));
const KycSection = React.lazy(() => import("../KycSection"));

const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const { profileData } = useSelector((state) => {
    return state?.app;
  });

  console.log(profileData)

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
    setOpen(false);
  };

  // const userPopover = () => {
  //   return (
  //     <section className="user-inner-container">
  //       <div className="profile-opt" onClick={handleProfileClick}>
  //         <Avatar size={20} src="https://joeschmoe.io/api/v1/random" />
  //         Profile
  //       </div>
  //       <div className="password-opt" onClick={handleChangeLogout}>
  //         <LoginOutlined />
  //         Change Password
  //       </div>
  //       <div className="logout-opt" onClick={handleLogout}>
  //         <LoginOutlined />
  //         Logout
  //       </div>
  //     </section>
  //   );
  // };

  const openMenu = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate(`/admin/profile`);
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("last-stand-admin-panel-user-data");
    dispatch(setUserData({}));
    navigate("/");
    setOpen(false);
  };

  return (
    <>
      <Header className="site-layout-background bugify-navbar">
        <div className="merchant-name">{profileData?.first_name} {profileData?.last_name}</div>
        <Space align="center" size={20}>
          <div
            placement="rightBottom"   
            className="user-popover"
            open={open}
          >
            <span onClick={()=>navigate("/support-ticket")}>Support Ticket</span> &nbsp;&nbsp; | &nbsp;&nbsp;
            <Avatar onClick={()=>navigate("/profile-setting")} src={profileData?.image} /> &nbsp;
            <span onClick={()=>navigate("/profile-setting")} className="admin-text">{profileData?.first_name} {profileData?.last_name}</span>
          </div>
        </Space>
      </Header>
      <FloatButton icon={<img src={FloatIcon} alt="#"/>} onClick={() => console.log('onClick')} />
      <Layout>
        <SiderBar toggle={toggle} collapsed={collapsed} />
        <Layout className="site-layout">
          <Content className="site-layout-background content-main-section">
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/transaction-history" element={<TransactionHistory />}/>
                <Route path="/withdraw-money" element={<WithDrawMoney />} />
                <Route path="/add-withdraw-method" element={<AddWithDrawMethod />} />               
                <Route path="/withdraw-history" element={<WithDrawHistory />} />
                <Route path="/profile-setting" element={<ProfileSettings />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/twofactor-authentication" element={<TwoFactorsAuthentication />} />
                <Route path="/my-qrcode" element={<MyQRCode />} />
                <Route path="/business-api" element={<BusinessApiKey />} />
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/add-support-ticket" element={<AddnewTicket />} />
                <Route path="/ticket-detail/:ticketId" element={<TicketDetail />} />
                <Route path="/logout-options" element={<LogoutDevices />} />
                <Route path="/screen-lock" element={<ScreenLock />} />
                <Route path="/kyc" element={<KycSection />} />
                {/*  */}
                
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
