import React, { useState } from "react";
import { Typography, Card, Button, Row, Col,message } from "antd";
import { useSelector } from "react-redux";
import API from "../../../utils/api";
import { getErrorMessage,getDataManager } from "../../../utils/helper.functions";
import OtpInput from "react-otp-input";
import "./styles.scss";

const { Title, Paragraph } = Typography;

const EmailVerificationModal = () => {

  const auth=new API.Auth()

  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const { emailOtp } = useSelector((state) => {
    return state?.app;
  });
  

  const onVerify = (payload) => {
    console.log(payload)
    getDataManager(auth?.codeValidate, setLoading, payload).then((x) => {
      if (x?.status === true) {
        message.success({
          content: "You have successfully logged in",
          duration: 3,
        });
        
      } else {
        const error = getErrorMessage(x?.errors);
        // dispatch(setUserData({}));
       
        message.error({
          content: x?.message || error || "Error ocurred while verification",
          duration: 3,
        });
      }
    });
  };

  const handleClick = () => {
    let payload = {};
    payload = {
      email: emailOtp,
      code: otp,
    };
    onVerify(payload)
  };

  return (
    <Row>
      <Col span={12} className="login-right-section"></Col>
      <Col span={12} className="login-left-section">
        <Card className="login-card" bordered={false}>
          {/* {loading && <Spin title="Verifying code..." />} */}
          <Title level={4} className="login-title">
            OTP Verification
          </Title>
          <div className="verification-inputs-render">
            <Paragraph className="thankyou-text">
              We have sent a one time password to your email address
            </Paragraph>
            <Paragraph className="thankyou-text">
              Please enter the otp code to verify your account
            </Paragraph>
            <OtpInput
              numInputs={4}
              value={otp}
              onChange={(e) => setOtp(e)}
              containerStyle="verification-code-container"
              inputStyle="one-digit-input"
            />
            <Paragraph className="thankyouu-text">
              I did'nt receive any code.
              <span className="sin-up-text">Resend</span>
            </Paragraph>
          </div>

          <Button type="primary" onClick={() => handleClick()}>
            Verify
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default EmailVerificationModal;
