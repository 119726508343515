import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";

const initialState = {
  userData: {},
  profileData:{},
  merchantWallets:[],
  emailOtp:{},
  showLockScreen:false,
};

export default handleActions(
  {
    [actions.setUserData](state, { payload }) {
      return produce(state, (state) => {
        state.userData = payload;
      });
    },
    [actions.setMerchnatWallets](state, { payload }) {
      return produce(state, (state) => {
        state.merchantWallets = payload;
      });
    },
    [actions.setProfileData](state, { payload }) {
      return produce(state, (state) => {
        state.profileData = payload;
      });
    },
    [actions.setOtpEmail](state, { payload }) {
      return produce(state, (state) => {
        state.emailOtp = payload;
      });
    },
    [actions.setShowLockScreen](state, { payload }) {
      return produce(state, (state) => {
        state.showLockScreen = payload;
      });
    },
  },
  initialState
);
