import { createAction } from "redux-actions";

export const setUserData = createAction(
  "SET_USER_DATA",
  (userData) => userData
);

export const setProfileData = createAction(
  "SET_PROFILE_DATA",
  (profileData) => profileData
);

export const setMerchnatWallets = createAction(
  "SET_MERCHANT_WALLETS",
  (merchantWallets) => merchantWallets
);

export const setOtpEmail = createAction(
  "SET_OTP_EMAIL",
  (emailOtp) => emailOtp
);

export const setShowLockScreen = createAction(
  "SET_SHOW_LOCK",
  (showLockScreen) => showLockScreen
);